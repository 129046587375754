<template>
  <component :is="contentComponent" @updateMeta="updatePageMetadata" />
</template>

<script>

const _m = () => import('@/pages/home/m.vue') //代码切割
const _pc = () => import('@/pages/home/pc.vue') //代码切割
import { mapGetters } from 'vuex';

export default {
  data () {
    return {
      contentComponent: null,
      changeCity: false,
      currentCity: 'Major Cities', // 初始城市名称
    };
  },
  computed: {
    ...mapGetters([
      'deviceType',
      "adsensConfig"
    ])
  },
  metaInfo () {
    return {
      title: `Current Time in ${this.currentCity} | TimeInCities.com`,
      meta: [
        {
          name: 'description',
          content: this.changeCity ? `Stay updated with the current time in ${this.currentCity} on TimeInCities.com. Find accurate and reliable time information, along with additional details about the city’s time zone, daylight saving changes, and local time-related tips.` : `Discover the current time in major cities around the world. TimeInCities.com provides accurate and up-to-date time information for cities globally. Plan your meetings, travels, and calls with ease.`,
        },
      ],
    };
  },
  created () {
    console.log(`deviceType - ${this.deviceType}`);
    if (this.deviceType == 'desktop' || this.deviceType == 'pc') {
      this.contentComponent = _pc
      return
    }
    this.contentComponent = _m
  },
  methods: {
    updatePageMetadata (cityName) {
      this.currentCity = cityName; // 更新当前城市名称
      this.changeCity = true
    },

  },
};
</script>
